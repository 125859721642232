exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-blogs-blog-one-js": () => import("./../../../src/pages/blogs/blog-one.js" /* webpackChunkName: "component---src-pages-blogs-blog-one-js" */),
  "component---src-pages-blogs-js": () => import("./../../../src/pages/blogs.js" /* webpackChunkName: "component---src-pages-blogs-js" */),
  "component---src-pages-case-studies-abi-villas-js": () => import("./../../../src/pages/case-studies/abi-villas.js" /* webpackChunkName: "component---src-pages-case-studies-abi-villas-js" */),
  "component---src-pages-case-studies-agam-builders-js": () => import("./../../../src/pages/case-studies/agam-builders.js" /* webpackChunkName: "component---src-pages-case-studies-agam-builders-js" */),
  "component---src-pages-case-studies-bileeta-js": () => import("./../../../src/pages/case-studies/bileeta.js" /* webpackChunkName: "component---src-pages-case-studies-bileeta-js" */),
  "component---src-pages-case-studies-cycling-buddy-js": () => import("./../../../src/pages/case-studies/cycling-buddy.js" /* webpackChunkName: "component---src-pages-case-studies-cycling-buddy-js" */),
  "component---src-pages-case-studies-foysonis-js": () => import("./../../../src/pages/case-studies/foysonis.js" /* webpackChunkName: "component---src-pages-case-studies-foysonis-js" */),
  "component---src-pages-case-studies-js": () => import("./../../../src/pages/case-studies.js" /* webpackChunkName: "component---src-pages-case-studies-js" */),
  "component---src-pages-case-studies-lanka-properties-js": () => import("./../../../src/pages/case-studies/lanka-properties.js" /* webpackChunkName: "component---src-pages-case-studies-lanka-properties-js" */),
  "component---src-pages-case-studies-nera-erp-js": () => import("./../../../src/pages/case-studies/nera-erp.js" /* webpackChunkName: "component---src-pages-case-studies-nera-erp-js" */),
  "component---src-pages-case-studies-osss-logistics-js": () => import("./../../../src/pages/case-studies/osss-logistics.js" /* webpackChunkName: "component---src-pages-case-studies-osss-logistics-js" */),
  "component---src-pages-case-studies-sri-ganesh-js": () => import("./../../../src/pages/case-studies/sri-ganesh.js" /* webpackChunkName: "component---src-pages-case-studies-sri-ganesh-js" */),
  "component---src-pages-case-studies-teen-girl-js": () => import("./../../../src/pages/case-studies/teen-girl.js" /* webpackChunkName: "component---src-pages-case-studies-teen-girl-js" */),
  "component---src-pages-component-typography-js": () => import("./../../../src/pages/component/typography.js" /* webpackChunkName: "component---src-pages-component-typography-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-index-old-js": () => import("./../../../src/pages/index-old.js" /* webpackChunkName: "component---src-pages-index-old-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-refund-policy-js": () => import("./../../../src/pages/refund-policy.js" /* webpackChunkName: "component---src-pages-refund-policy-js" */),
  "component---src-pages-services-artificial-intelligence-js": () => import("./../../../src/pages/services/artificial-intelligence.js" /* webpackChunkName: "component---src-pages-services-artificial-intelligence-js" */),
  "component---src-pages-services-digital-marketing-js": () => import("./../../../src/pages/services/digital-marketing.js" /* webpackChunkName: "component---src-pages-services-digital-marketing-js" */),
  "component---src-pages-services-full-stack-development-js": () => import("./../../../src/pages/services/full-stack-development.js" /* webpackChunkName: "component---src-pages-services-full-stack-development-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-services-mobile-application-development-js": () => import("./../../../src/pages/services/mobile-application-development.js" /* webpackChunkName: "component---src-pages-services-mobile-application-development-js" */),
  "component---src-pages-services-product-design-and-development-js": () => import("./../../../src/pages/services/product-design-and-development.js" /* webpackChunkName: "component---src-pages-services-product-design-and-development-js" */),
  "component---src-pages-services-remote-tech-team-js": () => import("./../../../src/pages/services/remote-tech-team.js" /* webpackChunkName: "component---src-pages-services-remote-tech-team-js" */),
  "component---src-pages-services-ui-ux-design-js": () => import("./../../../src/pages/services/ui-ux-design.js" /* webpackChunkName: "component---src-pages-services-ui-ux-design-js" */),
  "component---src-pages-services-web-development-js": () => import("./../../../src/pages/services/web-development.js" /* webpackChunkName: "component---src-pages-services-web-development-js" */),
  "component---src-pages-services-web-development-shopify-js": () => import("./../../../src/pages/services/web-development/shopify.js" /* webpackChunkName: "component---src-pages-services-web-development-shopify-js" */),
  "component---src-pages-services-web-development-static-js": () => import("./../../../src/pages/services/web-development/static.js" /* webpackChunkName: "component---src-pages-services-web-development-static-js" */),
  "component---src-pages-services-web-development-wordpress-js": () => import("./../../../src/pages/services/web-development/wordpress.js" /* webpackChunkName: "component---src-pages-services-web-development-wordpress-js" */),
  "component---src-pages-services-websites-for-tradies-js": () => import("./../../../src/pages/services/websites-for-tradies.js" /* webpackChunkName: "component---src-pages-services-websites-for-tradies-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

